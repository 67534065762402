import { useEffect, useState } from 'react';

import { getItemFromLocalStorage } from '@netfront/common-library';
import { useAuthentication, useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { SideBar } from '@netfront/ui-library';
import cx from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { IconMenu } from '../Icons';
import { LinkButton, BUTTON_CLASSES } from '../LinkButton';

import { PUBLIC_MENU_ITEMS } from './PublicHeader.constants';
import styles from './PublicHeader.module.css';

/**
 * @todo
 * Swap native buttons for netfront ui. Current library doesnt allow unstyled theme/classnames
 */

const PublicHeader = () => {
  const { isAuthenticated } = useAuthentication();
  const { isDomainReady } = useDomain();
  const { getLoginUrl, getRegistrationUrl, getLogoutUrl, getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { asPath } = useRouter();

  const [identityUrl, setIdentityUrl] = useState<string>('');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [loginUrl, setLoginUrl] = useState<string>();
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [organisationKey, setOrganisationKey] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');
  const [registrationUrl, setRegistrationUrl] = useState<string>();

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(getBaseUrl());
    setLoginUrl(getLoginUrl());
    setLogoutUrl(getLogoutUrl());
    setRegistrationUrl(getRegistrationUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    const lastProjectVisited = getItemFromLocalStorage('lastProjectVisited');

    if (!lastProjectVisited) {
      return;
    }

    const [lastVisitedProjectId, lastVisitedOrganisationKey] = lastProjectVisited.split('/');

    setOrganisationKey(lastVisitedOrganisationKey);
    setProjectId(lastVisitedProjectId);
  }, []);

  const dashboardUrl = organisationKey && projectId ? `/dashboard/${organisationKey}/${projectId}` : `${identityUrl}/dashboard`;

  return (
    <header className="absolute w-full z-2">
      <div className="flex items-center c-container c-container--large py-8">
        <div className="flex-1">
          <NextLink href="/">
            <span>
              <span className="sr-only">Link to homepage</span>
              <img alt="Link to homepage" className="max-w-28 md:max-w-60" src="/images/quickcodes-logo-full.svg" />
            </span>
          </NextLink>
        </div>

        <div className="none lg:flex">
          <nav aria-label="Main navigation" className="flex items-center">
            <ul className="flex items-center list-none mb-0">
              {PUBLIC_MENU_ITEMS.map(({ href, label }, key) => (
                <li key={`${label}-${key}`} className="ml-12 mb-0">
                  <LinkButton
                    additionalClassNames={cx(
                      `${BUTTON_CLASSES['subtle-link']} min-w-auto color-white text-lg hover:opacity-1 transition no-wrap`,
                      {
                        'opacity-75': asPath !== href,
                        'opacity-1': asPath === href,
                      },
                    )}
                    text={label}
                    url={href}
                  />
                </li>
              ))}
            </ul>
          </nav>

          <nav aria-label={!isAuthenticated ? 'Login and registration' : 'Logout and return to dashboard'} className="flex ml-20">
            {!isAuthenticated ? (
              <>
                {registrationUrl && (
                  <LinkButton additionalClassNames={`${BUTTON_CLASSES.red} mr-3 no-wrap`} text="Sign up for free" url={registrationUrl} />
                )}

                {loginUrl && <LinkButton additionalClassNames={`${BUTTON_CLASSES.white} no-wrap`} text="Sign in" url={loginUrl} />}
              </>
            ) : (
              <div className={styles['c-home-page__log-in-buttons']}>
                <LinkButton additionalClassNames={`${BUTTON_CLASSES.white} no-wrap`} text="Return to dashboard" url={dashboardUrl} />

                {logoutUrl && <LinkButton additionalClassNames={`${BUTTON_CLASSES.red} no-wrap`} text="Log out" url={logoutUrl} />}
              </div>
            )}
          </nav>
        </div>

        <div className="flex lg:none">
          <button aria-label="Main navigation button" className="border-none bg-transparent p-0 pointer" onClick={toggleSideMenu}>
            <IconMenu />
          </button>
          <SideBar isSideBarOpen={isSideMenuOpen} isCloseButtonVisible onClose={toggleSideMenu}>
            <div className="text-center mb-24">
              <NextLink href="/">
                <span>
                  <span className="sr-only">Link to homepage</span>
                  <img alt="Link to homepage" loading="lazy" src="/images/quickcodes-logo-full-bw.svg" />
                </span>
              </NextLink>
            </div>

            <nav aria-label="Main mobile navigation" className="mb-12">
              <ul className="list-none mb-0 p-0 text-center">
                {PUBLIC_MENU_ITEMS.map(({ href, label }, key) => (
                  <li key={`${label}-${key}`} className="mb-8 ml-0">
                    <LinkButton
                      additionalClassNames={cx(`${BUTTON_CLASSES['subtle-link']} color-black  text-lg hover:opacity-1 transition`, {
                        'opacity-75': asPath !== href,
                        'opacity-1': asPath === href,
                      })}
                      text={label}
                      url={href}
                    />
                  </li>
                ))}
              </ul>
            </nav>

            <nav
              aria-label={!isAuthenticated ? 'Login and registration' : 'Logout and return to dashboard'}
              className="flex flex-col items-center ml-0"
            >
              {!isAuthenticated ? (
                <>
                  {registrationUrl && (
                    <LinkButton additionalClassNames={`${BUTTON_CLASSES.red} mb-4 no-wrap`} text="Sign up for free" url={registrationUrl} />
                  )}

                  {loginUrl && <LinkButton additionalClassNames={`${BUTTON_CLASSES.white} no-wrap`} text="Sign in" url={loginUrl} />}
                </>
              ) : (
                <>
                  <LinkButton additionalClassNames={`${BUTTON_CLASSES.red} mb-4`} text="Return to dashboard" url={dashboardUrl} />

                  {logoutUrl && <LinkButton additionalClassNames={`${BUTTON_CLASSES.white}`} text="Log out" url={logoutUrl} />}
                </>
              )}
            </nav>
          </SideBar>
        </div>
      </div>
    </header>
  );
};

export { PublicHeader };
