import { useState, useEffect } from 'react';

import { saveItemWithExpiryToLocalStorage, removeItemFromLocalStorage } from '@netfront/common-library';
import { useProtectedRoute } from '@netfront/gelada-identity-library';
import { AppHeader, Skeleton } from '@netfront/ui-library';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { OrganisationMenu } from '../OrganisationMenu';
import { UserAccountMenu } from '../UserAccountMenu';

import { useGetPartnerLogoBrand, IDBPartnerLogoBrand, useToast } from '../../hooks';

const PrivateHeader = () => {
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const {
    query: { projectId: queryProjectId, organisationKey: queryOrganisationKey },
  } = useRouter();
  const { handleToastError } = useToast();

  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [partner, setPartner] = useState<IDBPartnerLogoBrand>();
  const [partnerExpiry, setPartnerExpiry] = useState<number>();
  const [partnerHexColor, setPartnerHexColor] = useState<IDBPartnerLogoBrand['brandAccentPrimary']>();
  const [partnerLogoUrl, setPartnerLogoUrl] = useState<string | undefined>();
  const [projectId, setProjectId] = useState<string>('');

  const { handleGetPartnerLogoBrand, isLoading = false } = useGetPartnerLogoBrand({
    fetchPolicy: 'no-cache',
    onCompleted: ({ partnerBrand: partnerData = null }) => {
      if (!partnerData) {
        removeItemFromLocalStorage('partner');
      } else {
        saveItemWithExpiryToLocalStorage('partner', JSON.stringify(partnerData), {
          currentTimeValueInMilliseconds: new Date().getTime(),
          expiryTime: {
            unit: 'hours',
            value: 1,
          },
        });
      }

      setPartner(partnerData as IDBPartnerLogoBrand);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!partnerExpiry) {
      return;
    }

    if (new Date().getTime() < partnerExpiry) {
      return;
    }

    removeItemFromLocalStorage('partner');
  }, [partnerExpiry]);

  useEffect(() => {
    const partnerStoredJSON = JSON.parse(String(window.localStorage.getItem('partner')));

    if (!partnerStoredJSON) {
      return;
    }

    setPartner(JSON.parse(String(partnerStoredJSON.value)) as IDBPartnerLogoBrand);
    setPartnerExpiry(Number(partnerStoredJSON.expiry));
  }, []);

  useEffect(() => {
    if (!partner) {
      return;
    }

    const { brandAccentPrimary, logo } = partner;

    if (logo) {
      setPartnerLogoUrl(logo.presignedUrl);
    }

    setPartnerHexColor(brandAccentPrimary);
  }, [partner]);

  useEffect(() => {
    if (!(isAuthenticated && projectId && organisationKey)) {
      removeItemFromLocalStorage('partner');

      return;
    }

    void handleGetPartnerLogoBrand({
      id: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, projectId, organisationKey]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);

  return (
    <>
      {isLoading ? (
        <div className="px-2 py-1">
          <Skeleton borderRadius="10px" height={60} highlightColor="#fff" enableAnimation />
        </div>
      ) : (
        <AppHeader
          additionalClassNames={cx({
            'c-app-header--partner-logo': partnerLogoUrl,
          })}
          background={partnerHexColor ?? ''}
          childrenMiddle={partnerLogoUrl ? <img alt="Link to dashboard" className="h-8" loading="lazy" src={partnerLogoUrl} /> : <></>}
          logo={{ imageSrc: partnerLogoUrl ? '/images/custom-quickcodes-logo.png' : '/images/quickcodes-logo-full.svg' }}
        >
          <>
            {organisationKey && <OrganisationMenu />}

            <UserAccountMenu />
          </>
        </AppHeader>
      )}
    </>
  );
};

export { PrivateHeader };
